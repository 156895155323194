import { Modal } from "react-responsive-modal";

// component to show the rabla terms description
const RablaTerms = ({ open, onClose, termspara1 }) => {
  return (
    <Modal open={open} onClose={onClose} center role="dialog">
      <div className="mx-5 my-6">
        <div className="uppercase font-suzukiBold mb-6 text-center text-sub-heading text-primary-blue">
          Terms and Conditions
        </div>
        <div className="text-center text-default">{termspara1}</div>
      </div>
    </Modal>
  );
};

export default RablaTerms;
