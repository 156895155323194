import Image from "next/image";
import LinkButton from "../button";
import { Constants } from "../../helpers/constants";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { toggleForm } from "../../redux/ActionCreator";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

// Models with Prices Component for RO-TECHNOLOGY , HU-FLEET, RO-HYBRID, HU-PREFERENCE, RO-RABLA PAGES
const OfferGrid = ({
  seaters,
  modelPrice,
  showCatalogue,
  propsArrow,
  homeSuzukiDetailCta,
  cardLink,
  hideCtas,
  soonPrice,
  vehicleType,
  image,
  model,
  prices,
  price,
  btnText,
  HUF,
  startPrice,
  perMonth,
  RablaPrice,
  RablaPricing,
  variant,
  fullBorderCtas,
  perfectSuzuki,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [pageUrl, setPageUrl] = useState("");
  const LobValue = useSelector(({ app }) => app.LobValue);
  const perfectSuzukiData = perfectSuzuki?.[0]?.cta_list;

  useEffect(() => {
    setPageUrl(window.location.origin);
  }, []);

  return (
    <div className="offer-grid ">
      <div className="offer-grid-img cursor-pointer">
        <Link href={cardLink ? cardLink : ""}>
          <Image
            src={image}
            objectFit="contain"
            width={310}
            height={200}
            alt=""
          />
        </Link>
      </div>

      <div className={`offer-grid-content ${homeSuzukiDetailCta}`}>
        <div className="homeSuzukiCard__details--info">
          <Link href={cardLink ? cardLink : ""}>
            <h2 className="mb-[10px] md:text-sub-heading lg:text-sub-heading uppercase cursor-pointer">
              {model}
            </h2>
          </Link>
          {prices && (
            <>
              {!HUF && (
                <h4 className="text-small2 mb-[4px]">
                  {Constants.preferenceProgramme.FROM_COUNTRY}
                </h4>
              )}
              {startPrice && (
                <p className="mb-0">
                  <span className="inline-block font-suzukiBold">
                    {startPrice}
                  </span>
                </p>
              )}
              {modelPrice && (
                <h4 className=" text-defaut-color text-small2 md:mb-[5px] lg:mb-[5px] mb-[3px]">
                  {modelPrice}
                </h4>
              )}
              {!perMonth && (
                <h5 className="text-small2 mb-[16px]">
                  {" "}
                  {price}
                  <span className=" font-suzukiRegular">
                    {" "}
                    {Constants.preferenceProgramme.PER_MONTH}
                  </span>
                </h5>
              )}
              {vehicleType && (
                <p className="m-0 text-small2 md:text-default">{vehicleType}</p>
              )}

              {seaters && <p>{seaters}</p>}
            </>
          )}

          {RablaPrice && (
            <div className="">
              <p className="m-0">
                Preț RABLA de la:
                <span className="text-section-heading font-suzukiBold">
                  {" "}
                  {RablaPricing}
                </span>
              </p>
              <p className="m-0">{variant}</p>
            </div>
          )}
          {soonPrice && (
            <h2 className=" text-default font-suzukiBold text-primary-blue">
              {soonPrice}
            </h2>
          )}
        </div>

        {!hideCtas &&
          (perfectSuzukiData?.length > 0 ? (
            <>
              {perfectSuzukiData?.map((data, index) => {
                const isForm =
                  data.form != null && data.form != "text" && data.form != "";
                return (
                  <div
                    className={`compareItem__CTAs ${index == 0 ? "mt-[10px]" : ""}`}
                    key={index}
                  >
                    <LinkButton
                      hideBorder={false}
                      type={"button_dark_arrow_button"}
                      buttontext={data?.cta_name}
                      href={
                        isForm
                          ? null
                          : data.cta_link.includes("http") ||
                              data.cta_link.includes("https")
                            ? data.cta_link
                            : pageUrl + "/" + data.cta_link
                      }
                      target={data?.cta_target == "true" ? "_blank" : "_self"}
                      onClick={() =>
                        isForm ? dispatch(toggleForm(data?.form)) : false
                      }
                      fontManage="md:text-[12px] lg:text-[12px] text-[10px]"
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="offer-btn">
              <LinkButton
                hideBorder={false}
                type={"button_dark_arrow"}
                buttontext={btnText}
                target="_blank"
                href={cardLink}
                fontManage="md:text-[14px] lg:text-[14px] text-[10px]"
              />
            </div>
          ))}

        {showCatalogue && (
          <div className="offer-btn">
            <LinkButton
              hideBorder={false}
              type={"button_catalogue_arrow"}
              propsArrow={propsArrow}
              buttontext={btnText}
              target="_blank"
              href={cardLink}
              fontManage="md:text-[14px] lg:text-[14px] text-[10px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferGrid;
